import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthStore } from '../../api/auth/auth.store';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  readonly authStore = inject(AuthStore);
  redirectTo: boolean = false;
  inviteUrl!: string;
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Access the signal's current value
    const isAuthenticated = this.authStore.userIsLoggedInSignal();

    if (isAuthenticated) {
      return true; // Allow navigation
    }

    // If not authenticated, redirect to the login page
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}

