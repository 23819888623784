import { components } from "../interfaces/swagger-types";


export interface Auth {
  jwtSignal: string | null;
  userSignal?: any | null | boolean;
  userIsLoggedInSignal: null | boolean;
  isLoadingSignal: boolean;
}

export const initialState: Auth = {
  jwtSignal: null,
  userSignal: null,
  userIsLoggedInSignal: null,
  isLoadingSignal: false,
};

export function createAuth() {
  return {

  } as Auth;
}