export interface LookupEntry {
  label: string;
  value: any;
  [key: string]: any;
}

export type Lookups = {
  statesSignal: LookupEntry[];
  trainingsSignal: LookupEntry[];
  coursesSignal: LookupEntry[];
  entityStatusSignal: LookupEntry[];
  courseStatusSignal: LookupEntry[];
  rolesSignal: LookupEntry[];
  industriesSignal: LookupEntry[];
  partnerStatusSignal: LookupEntry[];
};

export const initialState: Lookups = {
  statesSignal: [],
  trainingsSignal: [],
  coursesSignal: [],
  entityStatusSignal: [],
  courseStatusSignal: [],
  rolesSignal: [],
  industriesSignal: [],
  partnerStatusSignal: [],
};