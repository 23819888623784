import { ChangeDetectorRef, Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RouterOutlet, RouterLink } from '@angular/router';
import { MediaMatcher, BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { FooterComponent } from '../footer/footer.component';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BannerComponent } from '../banner/banner.component';
import { environment } from '../../../../../environments/environment';
import { NgClass, DatePipe, NgStyle } from '@angular/common';
import { UserAuthService } from '../../../../api/auth/user-auth.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { AppSidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  standalone: true,
  imports: [
    NgStyle,
    BannerComponent,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    NgClass,
    RouterOutlet,
    FooterComponent,
    DatePipe,
    RouterLink,
    AppSidebarComponent,
    SpinnerComponent
  ],
})
export class FullComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  mobileQuery: MediaQueryList;
  sidebarOpened = true;
  versionNumber!: { number: string; date: string };
  

  @ViewChild('snav', { static: false }) sideNav!: MatSidenav;
  @ViewChild('profileSnav', { static: false }) profileSnav!: MatSidenav;

  private readonly _mobileQueryListener: () => void;

  constructor(
    public router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public breakpointObserver: BreakpointObserver,
    private userService: UserAuthService,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener)
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.versionNumber = {
      number: environment.versionNumber.split('|')[0],
      date: environment.versionNumber.split('|')[1],
    };
  }

  closeSideNav() {
    if (!this.mobileQuery.matches) {
      this.sideNav.close();
    }
  }

  logout() {
    this.userService.logout();
  }
}
