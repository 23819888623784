import { Injectable, inject } from "@angular/core";
import { BaseService } from "../base.service";
import { LookupsStore } from './lookups.store';

@Injectable({
  providedIn: 'root'
})
export class LookupsService extends BaseService {
  readonly lookupsStore = inject(LookupsStore);

  getStates() {
    if (this.lookupsStore.statesSignal().length > 0) return;
    this.http.get('lookup/states').subscribe((states: any) => {
      this.lookupsStore.setLookup('statesSignal', this.stateMapLookup(states));
    });
  }

  getTrainings() {
    if (this.lookupsStore.trainingsSignal().length > 0) return;
    return this.http.get('lookup/trainings').subscribe((trainings: any) => {
      this.lookupsStore.setLookup('trainingsSignal', this.mapLookup(trainings));
    });
  }

  getCourses() {
    if (this.lookupsStore.coursesSignal().length > 0) return;
    return this.http.get('lookup/courses').subscribe((courses: any) => {
      this.lookupsStore.setLookup('coursesSignal', this.mapLookup(courses));
    });
  }

  getEntityStatuses() {
    if (this.lookupsStore.entityStatusSignal().length > 0) return;
    return this.http.get('lookup/entity-status').subscribe((status: any) => {
      this.lookupsStore.setLookup('entityStatusSignal', this.mapLookup(status));
    })
  }

  getCourseStatuses() {
    if (this.lookupsStore.courseStatusSignal().length > 0) return;
    return this.http.get('lookup/user-scormtrack-status').subscribe((status: any) => {
      this.lookupsStore.setLookup('courseStatusSignal', this.mapLookup(status));
    })
  }

  getRoles() {
    if (this.lookupsStore.rolesSignal().length > 0) return;
    return this.http.get('lookup/roles').subscribe((roles: any) => {
      this.lookupsStore.setLookup('rolesSignal', this.roleLookup(roles));
    })
  }

  getIndustries() {
    if (this.lookupsStore.industriesSignal().length > 0) return;
    return this.http.get('lookup/industries').subscribe((industries: any) => {
      this.lookupsStore.setLookup('industriesSignal', this.mapLookup(industries));
    })
  }

  getPartnerStatuses() {
    if (this.lookupsStore.partnerStatusSignal().length > 0) return;
    return this.http.get('lookup/partner-status').subscribe((status: any) => {
      this.lookupsStore.setLookup('partnerStatusSignal', this.mapLookup(status));
    })
  }

  mapLookup(lookup: any) {
    return lookup.map((item: any) => {
      return { value: item.key, label: item.text };
    });
  }

  roleLookup(lookup: any) {
    return lookup.map((item: any) => {
      return { value: item.id, label: item.name };
    });
  }

  stateMapLookup(lookup: any) {
    return lookup.map((item: any) => {
      return { value: item.key, label: item.text, text: item.abbr };
    });
  }
}
