import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { UserAuthService } from '../../api/auth/user-auth.service';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  userService: UserAuthService = inject(UserAuthService);
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const onlyRole: string = route.data['permissions'].only;
    const redirectTo: string = route.data['permissions'].redirectTo;
    if (onlyRole && !this.userService.userHasRole(onlyRole)) {
      if (redirectTo) {
        this.router.navigate([redirectTo]);
      }
      return false;
    }
    return true;
  }
};
