<div class="reg-containers site-container-1000 pt-8">
    <div class="w-full text-center">
        <h1>Forgot Password</h1>
    </div>
    <div class="w-full text-center mt-4">
        <p>Enter your email address and we'll send you a message with instructions to reset your password.</p>
    </div>
    <div class="w-full">
        <form [formGroup]="form" (ngSubmit)="onSubmit()" class="lg:w-1/2 mx-auto">
            <div class="sm:p-5">

    
                <div class="flex flex-col">
                  <div class="forgot-psw-email">
                    <mat-form-field style="width: 100%">
                      <input matInput placeholder="Email address" type="email" formControlName="email" />
                      <mat-error
                        *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                        class="text-danger support-text"
                        >You must include an email address.</mat-error
                      >
                      <mat-error
                        *ngIf="form.get('email')?.hasError('email') && form.get('email')?.touched"
                        class="text-danger support-text"
                        >You must include a valid email address.</mat-error
                      >
                      <mat-error *ngIf="!verifyEmail && !inviteEmail && form.get('email')?.hasError('message')">{{
                        form.controls['email'].getError('message')
                      }}</mat-error>
                    </mat-form-field>
                    
                    <div class="mt-4 relative z-[1]" *ngIf="verifyEmail">
                      <mat-error>
                        You need to confirm your email.
                        <span class="underline cursor-pointer" (click)="resendEmail()">Resend Verification Email</span>
                      </mat-error>
                    </div>
                    
                    <div class="mt-4 relative z-[1]" *ngIf="inviteEmail">
                      <mat-error class="text-danger support-text">
                        You have an open invitation.
                        <br/>
                        <span class="underline cursor-pointer" (click)="resendInvite(form.controls['email'].value)">Resend Invitation Email</span>
                      </mat-error>
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="submit" class="btn-primary large" (click)="form.markAllAsTouched()" [disabled]="disableFlag">
                      Send Email
                    </button>
                  </div>
                </div>
                <div class="mt-4 sm:mt-md mb-md text-center forgot-psw-title">
                  <p class="">Return to 
                    <a routerLink="/login" class="primary-link">Log In</a>
                  </p>
                </div>
              
            </div>
          </form> 
    </div>
    
</div>
