import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { NgIf, Location } from '@angular/common';
import { SnackbarComponent, ToastType } from '../../../../shared/components/layout/snackbar/snackbar.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TotpService } from '../../../../api/services/totp.service';
import { NgxMaskDirective } from 'ngx-mask';

export enum VerifyType {
  VerifyEmail = 1,
  VerifyForgotPassword = 2,
}

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    RouterModule,
    NgIf,
    NgxMaskDirective,
  ],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss'
})
export class VerifyEmailComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public form: UntypedFormGroup = Object.create(null);
  public disableFlag: boolean = false;
  userEmail!: string;
  private verifyType!: VerifyType;
  private formData!: any;
  private totpGuid!: string;
  disableResend: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private location: Location,
    private router: Router,
    private snackBar: MatSnackBar,
    private totpService: TotpService,
  ) {}


  ngOnInit() {
    this.disableFlag = true;
    const routeData = this.location.getState();
    if (routeData) {
      this.userEmail = routeData['email'];
      this.totpGuid = routeData['totpGuid'];
      this.verifyType = routeData['verifyType'];
      this.formData = routeData['formData'];
    }

    this.form = this.fb.group({
      email: [this.userEmail],
      verificationCode: ['', [Validators.required, Validators.maxLength(6)]],
    });

    this.form.valueChanges.subscribe(() => {
      this.disableFlag = this.form.invalid;
    });
  }


  onSubmit(): void {
    this.disableFlag = true;
    if (this.form.valid) {
      switch (this.verifyType) {
        case VerifyType.VerifyForgotPassword:
          // for resetting password
          this.totpService.totpValidate(this.userEmail, this.form.value.verificationCode).subscribe({
            next: () => {
              this.router.navigateByUrl('/reset', { state: { email: this.userEmail, totpGuid: this.totpGuid } });
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: {
                  toastType: ToastType.Success,
                  message: 'Verification Complete. You can now reset your password',
                },
              });
            },
            error: () => {
              this.disableFlag = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Error, message: 'TOTP code is invalid, please try again.' },
              });
            },
          });
          break;
        case VerifyType.VerifyEmail:
          this.totpService.totpConfirmEmail(this.userEmail, this.form.value.verificationCode).subscribe({
            next: () => {
              this.router.navigateByUrl('/login');
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Success, message: 'Email Confirmed.' },
              });
            },
            error: () => {
              this.disableFlag = false;
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Error, message: 'TOTP code is invalid, please try again.' },
              });
            },
          });
          break;
      }
    }
  }

  resendTotp(): void {
    this.totpService.totpResend(this.userEmail, this.verifyType).subscribe({
      next: (totpGuid) => {
        if (typeof totpGuid === 'string' && totpGuid) {
          this.totpGuid = totpGuid;
        }
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Success, message: 'Verification Code Resent' },
        });

        this.disableResend = true;
        setTimeout(() => {
          this.disableResend = false;
        }, 3000);
      },
      error: () => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Error, message: 'Something went wrong, please try again.' },
        });
      },
    });
  }
}


