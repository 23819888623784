import { inject, Injectable } from "@angular/core";
import { LookupsStore } from "./lookups/lookups.store";
import { DashboardStore } from "./dashboard/dashboard.store";
import { OrganizationStore } from "./organizations/organization.store";
import { DomainStore } from "./domains/domains.store";
import { LearnerReportsStore } from "./reports/learner-reports/learner-reports.store";
import { QuarterlyReportsStore } from "./reports/quarterly-reports/quarterly-reports.store";
import { PartnersStore } from "./partners/partners.store";
import { AuthStore } from "../auth/auth.store";

// All stores should be imported here
export const AppStores = [
  LookupsStore,
  DashboardStore,
  OrganizationStore,
  DomainStore,
  LearnerReportsStore,
  QuarterlyReportsStore,
  PartnersStore,
  AuthStore
];


@Injectable({
  providedIn: 'root',
})
export class Stores {
  stores:any[] = [];
  constructor() {
    AppStores.forEach(store => {
      const storeInject = inject(store);
      this.stores.push(storeInject);
    });
  }
  
  resetStores() {
    this.stores.forEach(store => {
      if (store.resetStore)
      store.resetStore();
    });
  }
}
