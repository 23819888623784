import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, of, switchMap, take, throwError } from "rxjs";
import { Stores } from "../services/stores";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private stores: Stores) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    return of(token).pipe(
      take(1),
      switchMap((jwt) => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${jwt}`,
          },
        });

        return next.handle(request).pipe(
          catchError((error) => {
            if (error.status === 403) {
              this.stores.resetStores();
              localStorage.removeItem('token');
              window.location.reload();
            }
            return throwError(() => error);
          }));
      })
    );
  }
}