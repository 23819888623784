import { Component, effect, inject } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { AuthStore } from '../../../../api/auth/auth.store';
import { FooterComponent } from '../footer/footer.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'app-blank',
  templateUrl: './blank.component.html',
  styleUrls: [],
  standalone: true,
  imports: [NgIf, MatToolbarModule, MatSidenavModule, RouterOutlet, FooterComponent, NgClass],
})
export class AppBlankComponent {
  readonly authStore = inject(AuthStore);

  constructor(
    public router: Router,
  ) {
    effect(() => {
      if (this.authStore.userSignal && this.authStore.userSignal()) {
        this.router.navigateByUrl('/organizations');
      }
    });
  }

}