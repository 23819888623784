<footer
  class="border-t border-color-ddd flex flex-col lg:flex-row text-primary items-start lg:items-center justify-start lg:justify-center copywrite p-3 pr-8">
  <div>
    <span>
      <p class="small"> &copy; {{ date }} MRT State Reporting</p>
    </span>
  </div>
  <div>
    <ng-container *ngIf="!loggedIn">
      <p class="small">
        <a href="https://mandatedreportertraining.com/company/terms-of-use" target="_blank"
          class="primary-link"><span class="mx-1 hidden lg:inline-block font-normal">|</span>Terms Of
          Use<span class="mx-1 font-normal">|</span></a>
        <a href="https://mandatedreportertraining.com/company/privacy-statement" target="_blank"
          class="primary-link">Privacy Statement<span class="mx-1 font-normal">|</span></a>
        <a href="https://mandatedreportertraining.com/company/accessibility" target="_blank"
          class="primary-link">Accessibility</a>
      </p>
    </ng-container>
  </div>
  <div>
    <p class="small">
      <span class="mx-1 hidden lg:inline-block">|</span>
      <span class="mr-1 w-full">Powered by <a href="https://simplelearning.com" target="_blank"
          class="primary-link">Simple</a>
      </span>
    </p>
  </div>
</footer>