import { ChangeDetectorRef, Component, OnDestroy, EventEmitter, Output, Input, effect, inject } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems, Menu } from '../menu-items/menu-items';

import { Observable } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
 import { AccordionAnchorDirective } from '../../../directives/accordion/accordionanchor.directive';
import { AccordionLinkDirective } from '../../../directives/accordion/accordionlink.directive';
import { AccordionDirective } from '../../../directives/accordion/accordion.directive';
import { NgFor, NgIf } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AuthStore } from '../../../../api/auth/auth.store';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['sidebar.component.scss'],
    standalone: true,
    imports: [
      MatListModule,
        AccordionDirective,
        NgFor,
        AccordionLinkDirective,
        AccordionAnchorDirective,
        RouterLinkActive,
        NgIf,
        RouterLink,
        MatIconModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA] // Add CUSTOM_ELEMENTS_SCHEMA here
})
export class AppSidebarComponent implements OnDestroy {
  readonly authStore = inject(AuthStore);
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  status = true;
  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;
  mi$!: Observable<any[]>;
  @Output() eventEmitter = new EventEmitter();
  @Input() sidebarOpened!: boolean;
  menuChildren!: any;
  sideMenuitems!: Menu[];
  sideMenu: any[] = []

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);
    effect(() => {
      if (this.authStore.userSignal && this.authStore.userSignal()) {
        this.sideMenuitems = this.menuItems.getMenuitem();
      }
    });
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0,
    });
  }

  logout() {
    // this.authService.logout();
  }

  closeSideBar() {
    this.eventEmitter.emit();
  }
}
