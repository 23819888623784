import { inject, Injectable } from '@angular/core';
import { catchError, of, switchMap, tap, throwError } from 'rxjs';
import { AuthApiService } from './auth-api.service';
import { UserService } from '../services/user.service';
import { AuthStore } from './auth.store';
import { Stores } from '../../api/services/stores';
import { Router } from '@angular/router';

//TODO generate swagger types
//export type User = components['schemas']['UserViewModel'];

@Injectable({
  providedIn: 'root',
})
export class UserAuthService {
  authStore = inject(AuthStore);

  constructor(
    public router: Router,
    private authApiService: AuthApiService,
    private userService: UserService,
    private stores: Stores
  ) {
    const token = window.localStorage.getItem('token');
    if (token) {
      this.authStore.setJwt(token);
      this.authStore.setUserIsLoggedIn(true);
    }

    if (this.authStore.jwtSignal && this.authStore.jwtSignal()) {
      this.loadUserDetails();
    }
  }

  login(email: string, password: string, verificationCode: string) {
    this.authStore.setLoading(true);
    return this.authApiService.login2fa(email, password, verificationCode).pipe(
      tap((response) => {
        if (response !== null) {
          this.setToken(response.jwt!);
          this.authStore.setJwt(response.jwt!);
        }
      }),
      switchMap(() => this.userService.getUser()),
      tap((user) => {
        this.authStore.setUser(user);
        this.authStore.setLoading(false);
        if (user !== null) {
          this.authStore.setUserIsLoggedIn(true);
        }
      }),
      catchError((error) => {
        this.authStore.setLoading(false);
        return throwError(() => error);
      })
    );
  }

  loadUserDetails() {
    if (!this.authStore.jwtSignal()) {
      this.authStore.setUser(null);
      this.authStore.setUserIsLoggedIn(false);
      return;
    }

    if (this.authStore.userSignal && this.authStore.userSignal()) {
      return;
    }

    this.authStore.setLoading(true);
    this.userService.getUser().pipe(
      catchError((error) => {
        this.authStore.setLoading(false);
        return of(false);
      })
    ).subscribe({
      next: (user:any) => {
        this.authStore.setLoading(false);
        if (!user) {
          this.logout();
          return;
        }
        this.authStore.setJwt(window.localStorage.getItem('token'));
        this.authStore.setUser(user);
        this.authStore.setUserIsLoggedIn(true);
      }
    });
  }

  public setToken(jwt: string) {
    window.localStorage.setItem('token', jwt);
    this.authStore.setJwt(jwt);
    this.authStore.setUserIsLoggedIn(true);
  }

  public logout() {
    window.localStorage.removeItem('token');
    this.stores.resetStores();
    this.router.navigate(['/login']);
  }

  get isLoggedIn() {
    return this.authStore;
  }

  public userHasRole(role: string): boolean {

    if (this.authStore.userSignal) {
      const store = this.authStore.userSignal() ?? {};
      if (!store || !store.roles) {
        return false;
      }
      return store.roles.includes(role);
    }

    return false;
  }
}
