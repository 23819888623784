import { Routes } from '@angular/router';
import { AppBlankComponent } from './shared/components/layout/blank/blank.component';
import { LoginComponent } from './routes/authentication/components/login/login.component';
import { FullComponent } from './shared/components/layout/full/full.component';
import { AuthGuard } from './routes/guards/auth.guard';
import { RegisterComponent } from './routes/authentication/components/register/register.component';
import { RoleGuard } from './routes/guards/role.guard';
import { OrganizationComponent } from './routes/organization/organization.component';
import { LearnerReportComponent } from './routes/reports/learner-report/learner-report.component';
import { TrainingReportComponent } from './routes/reports/training-report/training-report.component';
import { InteractionReportComponent } from './routes/reports/interaction-report/interaction-report.component';
import { QuarterlyReportComponent } from './routes/reports/quarterly-report/quarterly-report.component';
import { ResetPasswordComponent } from './routes/authentication/components/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './routes/authentication/components/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './routes/authentication/components/verify-email/verify-email.component';
import { CheckinboxComponent } from './routes/authentication/components/checkinbox/checkinbox.component';
import { DomainsComponent } from './routes/domains/domains.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';

const AUTH_ROUTES: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
  },
  {
    path: 'reset',
    component: ResetPasswordComponent,
  },
  {
    path: 'verify-email',
    component: VerifyEmailComponent,
  },
  {
    path: 'checkinbox',
    component: CheckinboxComponent,
  }

];

const REPORTS_ROUTES: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/report/learner' },
  {
    path: 'learner',
    component: LearnerReportComponent
  },
  {
    path: 'training',
    component: TrainingReportComponent
  },
  {
    path: 'interaction',
    component: InteractionReportComponent
  },
  {
    path: 'quarterly',
    component: QuarterlyReportComponent
  }
];

export const routes: Routes = [
  {
    path: '',
    component: AppBlankComponent,
    loadChildren: () => AUTH_ROUTES,
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    loadChildren: () => [
      { path: '', pathMatch: 'full', redirectTo: 'organizations' },
      {
        path: 'organizations',
        component: OrganizationComponent
      },
      {
        path: 'domains',
        component: DomainsComponent
      },
      {
        path: 'report',
        loadChildren: () => REPORTS_ROUTES
      },
      {
        path: 'partners',
        canActivate: [RoleGuard],
        loadComponent: () => import('./routes/partner/partner.component').then((m) => m.PartnerComponent),
        data: {
          permissions: {
            only: 'Admin',
            redirectTo: '/organizations',
          },
        },
      },
      {
        path: 'partner-edit/:id',
        canActivate: [RoleGuard],
        loadComponent: () => import('./routes/partner/partner-edit/partner-edit.component').then((m) => m.PartnerEditComponent),
        data: {
          permissions: {
            only: 'Admin',
            redirectTo: '/organizations',
          },
        },
      },
    ],
  },
  { path: '404', component: ErrorComponent, },
  { path: '401', component: UnauthorizedComponent, },
  {
    path: '**',
    redirectTo: '/404',
  },
];
