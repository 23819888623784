<div class="preloader" *ngIf="authStore.isLoadingSignal()">
  <div class="spinner">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
  </div>
</div>
  <div class="flex flex-col justify-between" style="height: 100dvh;" [ngClass]="{'invisible': authStore.isLoadingSignal()}">
    <div class="h-full">
      <mat-toolbar class="auth-header">
        <div class="w-full">
          <div class="flex justify-between items-center">
            <div class="logo">
              <a href="https://mandatedreportertraining.com/" class="header-logo regular-course-icon">
                <img src="assets/MandatedReporterTraining-logo.svg" alt="Mandated Reporter Training" />
              </a>
            </div>
          </div>
        </div>
      </mat-toolbar>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </div>