import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ReactiveFormsModule, FormsModule  } from '@angular/forms';
import { ValidationErrors } from '../../../../api/interfaces/validation-errors';
import { catchError, of, Subject, takeUntil, throwError } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterModule, Router, RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';
import { SnackbarComponent, ToastType } from '../../../../shared/components/layout/snackbar/snackbar.component';
import { AccountService } from '../../../../api/services/account.service';
import { VerifyType } from '../verify-email/verify-email.component';


@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    RouterModule,
    NgIf,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();
  public form: UntypedFormGroup = Object.create(null);
  verifyEmail: boolean = false;
  inviteEmail: boolean = false;
  disableFlag: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private accountService: AccountService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}


  ngOnInit() {
    this.form = this.fb.group({
      email: this.fb.control(null, Validators.compose([Validators.required, Validators.email])),
    });
  }


  onSubmit() {
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        // @ts-ignore
        validationErrors.forEach((error) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else if (validationErrors.error) {
        const control = this.form.get('email');
        if (control) {
          this.form.markAllAsTouched();
          control.setErrors({
            message: validationErrors?.error.user[0],
          });
        }
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${validationErrors?.error.user[0]}` }})
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
      }

      return of(null);
    };

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const email = this.form.get('email')?.value;
    this.accountService
    .forgotPassword({ email })
    .pipe(catchError((error) => throwError(() => error)))
    .subscribe({
      next: (totpGuid) => {
        this.disableFlag = false;
        const email = this.form.get('email')?.value;
        const forgot = true;
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Success, message: 'Verification Email Sent' },
        });
        this.router.navigateByUrl('/verify-email', {
          state: { email, forgot, totpGuid, verifyType: VerifyType.VerifyForgotPassword },
        });
      },
      error: (error) => {
        validationErrorHandler(error);
        this.disableFlag = false;
      },
    });
  }

  resendEmail() {
    console.log('resend email clicked');
  }

  resendInvite(email: string) {
    console.log('resend invite clicked');
  }

}
