import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthApiService } from '../../../../api/auth/auth-api.service';
import { catchError } from 'rxjs';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackbarComponent, ToastType } from '../../../../shared/components/layout/snackbar/snackbar.component';
import { UserAuthService } from '../../../../api/auth/user-auth.service';
import { VerifyType } from '../verify-email/verify-email.component';
import { TotpService } from '../../../../api/services/totp.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    MatFormFieldModule,
    ReactiveFormsModule,
    NgClass,
    MatInputModule,
    MatIconModule,
    MatSnackBarModule,
  ],
})
export class LoginComponent implements OnInit {
  form: FormGroup = new FormGroup({});
  hide = true;
  disabled = false;
  disableFlag = false;
  loginSuccessful = false;
  is2faSetup = true;
  qrCode: any; 

  is2FaRequired: boolean = true;

  downloadApp: boolean = true;
  setupVerifyAuthCode: boolean = false;
  verifyAuthCode: boolean = false;
  verifyEmail: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthApiService,
    private userService: UserAuthService,
    private snackBar: MatSnackBar,
    private router: Router,
    private totpService: TotpService,
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: this.fb.control(null, [Validators.required, Validators.email]),
      password: this.fb.control(null, Validators.required),
      verificationCode: this.fb.control(null),
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.disabled = true;
      this.authService
      .loginValidation(this.form.value.email, this.form.value.password)
      .subscribe({
        next: (image: any) => {
          this.qrCode = new Blob([image], { type: 'image/bitmap' });
          if (this.qrCode.size > 0) {
            this.is2faSetup = false;
            this.disabled = false;
            this.loginSuccessful = true;

            this.downloadApp = true;
          }  else {
            this.is2faSetup = true;
            this.verifyAuthCode = true;
            this.disabled = false;
            this.loginSuccessful = true;
            this.form.get('verificationCode')?.setValidators(Validators.required);
          }
        },
        error: (error) => {
          this.disableFlag = false;
          this.verifyEmail = false;
          if (error.verify_email) {
            this.verifyEmail = true;
          } else if (error.deactivated_user) {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: 'Your account has been deactivated' },
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, {
              duration: 3000,
              data: { toastType: ToastType.Error, message: error.message || 'Invalid username or password' },
            });
          }
          this.disabled = false;
        },
      });
    }
  }

  onSubmit2fa() {
    if (this.form.valid) {
      this.disabled = true;
      this.userService
        .login(this.form.value.email, this.form.value.password, this.form.value.verificationCode)
        .pipe(
          catchError((error) => {
            this.disabled = false;
            if (error.deactivated_user) {
              this.snackBar.openFromComponent(SnackbarComponent, {
                duration: 3000,
                data: { toastType: ToastType.Error, message: 'Your account has been deactivated' },
              });
            }
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `Invalid 2FA code` }});
            return error;
          }),
        )
        .subscribe(() => {
          this.disabled = false;
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: `Login successful` }});
          this.router.navigate(['/organizations']);
        });
    }
  }



  showQRCode() {
    this.downloadApp = false;
    this.setupVerifyAuthCode = true;

    if (this.qrCode.size > 0) {
      this.is2faSetup = false;
      const reader = new FileReader();
      reader.readAsDataURL(this.qrCode);
      reader.onloadend = () => {
        const dataUrl = reader.result;
        const img = document.createElement('img');
        img.src = dataUrl as string;
        document.getElementById('qrCode')?.appendChild(img);
      };
    }
  }

  showVerifyAuthCode() {
    this.setupVerifyAuthCode = false;
    this.verifyAuthCode = true;
  }

  resendEmail() {
    this.totpService.totpResend(this.form?.get('email')?.value?.trim(), VerifyType.VerifyEmail).subscribe({
      next: (totpGuid) => {
        const email = this.form?.get('email')?.value?.trim();
        const forgot = false;
        this.router.navigateByUrl('/verify-email', { state: { email, totpGuid, verifyType: VerifyType.VerifyEmail } });
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 4000,
          data: { toastType: ToastType.Success, message: 'Verification Code Resent' },
        });
      },
      error: (error) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Error, message: 'Something went wrong, please try again.' },
        });
      },
    });
  }
}
