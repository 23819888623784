import { Injectable } from '@angular/core';
import { BaseService } from '../services/base.service';
import { components } from '../interfaces/swagger-types';
import { catchError, map, Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends BaseService {
  loginValidation(email: string, password: string) {
    return this.http.post('authentication/login-validation', 
      { email, password }, 
      {
        observe: 'response',
        responseType: 'blob',
      }
    ).pipe(
      map((response) => {
        return response.body;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Blob) {
          return this.extractErrorMessage(error.error);
        }
        return throwError(() => error);
      })
    );
  }

  login2fa(email: string, password: string, verificationCode: string) {
    type LoginResponse = components['schemas']['JWTViewModel'];
    return this.http.post<LoginResponse>('authentication/login-2fa', { email, password, verificationCode });
  }

  private extractErrorMessage(errorBlob: Blob): Observable<never> {
    return new Observable((observer) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const errorJson = JSON.parse(reader.result as string);
          observer.error(errorJson);
        } catch {
          observer.error({ message: 'An unknown error occurred.' });
        }
      };
      reader.onerror = () => {
        observer.error({ message: 'Failed to read error response.' });
      };
      reader.readAsText(errorBlob);
    });
  }
}
